/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*
    RESET END
*/


@font-face {
    font-family: "Macula Solid";
    src: url("./macula-solid-v10.woff") format("woff");
}
  
:root {
    --perspective-dist: 200px;
}  

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html {
    font-size: 16px;
}

/*
    MAIN CONTENT
*/
main {
    position: relative;
    display: block;
    padding: 1rem;
    font-family: Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;
}

header {
    display: flex;
    justify-content: space-between;
}

.pronouns {
    padding-left: 0.25rem;
}

h1,
h3 {
    font-family: "Macula Solid", "Courier New", "Courier", "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
    margin-bottom: 0.5rem;
}

h1 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-top: 1rem;
}

strong {
    font-weight: bold;
}

button {
    height: 2.75rem;
    background: none;
}

p {
    margin-bottom: 1rem;
}

ul {
    padding-left: 1.5rem;
    list-style-type: disclosure-closed;
}

ol {
    padding-left: 2rem;
    list-style-type: lower-greek;
}

.reading-text {
    margin: 0.25rem 0 0;
    flex-grow: 1;
    line-height: 1.5rem;
}

.list-sections {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1.5rem;
}

.contact-details {
    padding-left: 0.25rem;
    margin-bottom: 0;
}

.samples {
    margin-top: 1rem;
}

@media (min-width: 768px) {
    main {
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    header {
        display: block;
    }
    button {
        height: auto;
    }
    h3 {
        font-size: 2rem;
        line-height: 2rem;
    }
    .reading-text {
        margin: 0.25rem 2.5rem 0;
        flex-grow: 1;
        line-height: 1.5rem;
    }
    .list-sections {
        grid-template-columns: 1fr 1fr 1fr;
        margin: 2rem 0;
    }
}

@media (min-width: 1024px) {
    h1 {
        font-size: 3rem;
    }    
}


/*
    BACKGROUND
*/
.background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    background: linear-gradient(121deg, rgba(91,206,250,1) 0%, rgba(245,169,184,1) 40%, rgba(200,200,200,1) 50%, rgba(245,169,184,1) 60%, rgba(91,206,250,1) 100%);
    background-size: 400% 400%;
    animation: gradient-anim 30s ease infinite;
}

.hidden {
    display: none;
}

.bg-cell {
    border: 2px solid black;
    opacity: 0.05;
}

.color-0 {
    background-color: #FCF434;
}

.color-1 {
    background-color: #FFFFFF;
}

.color-2 {
    background-color: #9C59D1;
}

.color-3 {
    background-color: #2C2C2C;
}


.rotation-0 {
    opacity: 0;
    animation: rotation-0-animation 10s linear 0s infinite alternate-reverse;
}

.rotation-1 {
    opacity: 0;
    animation: rotation-1-animation 10s linear 0s infinite alternate-reverse;
}

.rotation-2 {
    opacity: 0;
    animation: rotation-2-animation 10s linear 0s infinite alternate-reverse;
}

.rotation-3 {
    opacity: 0;
    animation: rotation-3-animation 10s linear 0s infinite alternate-reverse;
}

.tf-origin-0 {
    transform-origin: 0% 0%;
}
.tf-origin-1 {
    transform-origin: 25% 25%;
}
.tf-origin-2 {
    transform-origin: 50% 50%;
}
.tf-origin-3 {
    transform-origin: 75% 75%;
}
.tf-origin-4 {
    transform-origin: 100% 100%;
}

@keyframes rotation-0-animation {
    from {
        transform: perspective(var(--perspective-dist)) rotateX(45deg);
        opacity: 0.05;
    }
    to {
        opacity: 0.05;
        transform: perspective(var(--perspective-dist)) rotateX(-45deg);
    }
}

@keyframes rotation-1-animation {
    0% {
        transform: perspective(var(--perspective-dist)) rotateX(0deg);
        opacity: 0.05;
    }
    100% {
        opacity: 0.05;
        transform: perspective(var(--perspective-dist)) rotateX(90deg);
    }
}

@keyframes rotation-2-animation {
    from {
        transform: perspective(var(--perspective-dist)) rotateY(45deg);
        opacity: 0.05;
    }
    to {
        opacity: 0.05;
        transform: perspective(var(--perspective-dist)) rotateY(-45deg);
    }
}

@keyframes rotation-3-animation {
    0% {
        transform: perspective(var(--perspective-dist)) rotateY(0deg);
        opacity: 0.05;
    }
    100% {
        opacity: 0.05;
        transform: perspective(var(--perspective-dist)) rotateY(90deg);
    }
}

/* https://codepen.io/P1N2O/pen/pyBNzX */
@keyframes gradient-anim {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

